import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios'
import * as filters from './filters'

Vue.use(Vuex)
Vue.use(VueRouter)

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

const store = new Vuex.Store({
    state: {
        webInfo: {}
    },
    mutations: {
        setWebInfo(state, webInfo) {
            state.webInfo = webInfo
        }
    }
})

const API = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 1000 * 120
});
Vue.prototype.$api = API

import App from './App.vue'
import Index from './pages/Index.vue'
import Pay from './pages/Pay.vue'
import Paying from './pages/Paying.vue'
import Result from './pages/Result.vue'
import Success from './pages/Success.vue'
import OneononeIndex from './pages/oneonone/Index.vue'
import OneononePay from './pages/oneonone/Pay.vue'
import OneononeSuccess from './pages/oneonone/Success.vue'

const routes = [
    { path: '/', component: Index, meta: { title: '抑郁（SDS）测试—专业版' } },
    { path: '/pay', component: Pay, meta: { title: '测试报告' } },
    { path: '/paying', component: Paying, meta: { title: '支付' } },
    { path: '/result', component: Result, meta: { title: '测试结果' } },
    { path: '/success', component: Success, meta: { title: '支付成功' } },
    { path: '/oneonone/', component: OneononeIndex, meta: { title: '一对一解答' } },
    { path: '/oneonone/pay/:order_no', component: OneononePay, meta: { title: '确认支付' } },
    { path: '/oneonone/success', component: OneononeSuccess, meta: { title: '添加老师微信' } },
]

const router = new VueRouter({
    mode: 'history',
    routes
})


router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
router.afterEach(() => {
    window.scroll(0, 0)
})

Vue.config.productionTip = false

new Vue({
    router,
    store: store,
    render: h => h(App),
}).$mount('#app')
