<template>
    <div class="page page--success">
        <div class="page__header">
            <div class="page__header-icon">
                <img src="@/assets/images/success.png">
            </div>
            <strong>支付成功</strong>
        </div>
        <div class="page__content">
            <div class="desc">
                <strong>请关注公众号查看报告</strong><br>
                <img ref="qrcode" src="@/assets/images/wxoa_qrcode.png"><br>
                直接<dfn>截屏保存</dfn>二维码<br>
                用<dfn>微信扫码</dfn>关注公众号<br>
                <em>（不要长按二维码保存）</em><br>
                <a href="https://work.weixin.qq.com/kfid/kfcde28c2339667119b" target="_blank">联系客服</a><br>
                <small>（测评报告公众号永久保存，方便查看）</small>
            </div>
            <div class="steps">
                <div class="steps__title">查看报告步骤：</div>
                <dl>
                    <dt>一、关注公众号</dt>
                    <dd>
                        用付款的微信账号关注，支付宝用户也先关注公众号<br><br>
                        (1)方法一：直接截屏保存图片，打开微信，点开微信左上角“<b>+</b>”号 <img src="@/assets/images/next.png"> 点击<b>“扫一扫”</b> <img src="@/assets/images/next.png"> 打开图片扫码刚截图的二维码 <img src="@/assets/images/next.png"> <b>关注微信公众号</b>。<br><br>
                        (2)方法二：打开微信，点击微信右上角“<b>+</b>”号 <img src="@/assets/images/next.png"> 点击<b>“添加朋友”</b> <img src="@/assets/images/next.png"> 选择<b>“公众号”</b> <img src="@/assets/images/next.png"> 输入<b>“乾森心理”</b>关注公众号。<br><br>
                        (3)方法三：（仅微信支付用户）打开<b>“微信”</b>，找到支付订单，打开<b>“订单详情”</b>在订单详情页最下方找到<b>“联系商家”</b>一栏，点击<b>“公众号”</b>，然后关注。
                    </dd>
                    <dt>二、查询报告</dt>
                    <dd>
                        (1)进入公众号，点击左下角按钮切换成输入框，在输入框中输入<b>“1”</b>，<b>点击发送</b>按钮。<br><br>
                        (2)公众号会立即回传您的专属测评报告，点击<b>“点我查看测评结果”</b>即可查看报告，报告将永久在微信公众号，方便您查看。<br><br>
                        (3)支付宝支付用户，关注公众号后需要在支付宝APP中拷贝或复制出<b>商家订单号</b>，在<b>公众号里面发送</b>，即可获取您的测评报告。
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Success',
    data() {
        return {
            order: {}
        }
    },
    mounted() {
        this.fetchQr()
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.$api.post('/order/info', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    this.order = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        fetchQr() {
            this.$api.post('/wxoa/qr', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.ticket) {
                    this.$refs.qrcode.src = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.ticket
                }
            }).catch(() => {
            }).finally(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/success.scss';
</style>
