<template>
    <div class="page page--success">
        <div class="page__header">
            为了给你更好的体验，根据您的所属报告分配了<i>一位专属老师，不限时长，任何疑问</i>都可以在线咨询。
            <button type="button">解锁老师分析解答</button>
        </div>
        <div class="page__content">
            <div class="section">
                <div class="section__heading">
                    <h3>分析解答</h3>
                </div>
                <img src="@/assets/images/oneonone_img1.png" width="100%">
                <div class="tips">
                    &diams;&nbsp;常常感到不开心，负能量爆棚?<br>
                    &diams;&nbsp;开始回避社交，对身边的事提不起兴趣?<br>
                    &diams;&nbsp;感到身体被掏空，记忆力下降，变得越来越“傻”了？<br>
                    &diams;&nbsp;怀疑人生的意义，对未来不抱希望，恐慌害怕?<br>
                    &diams;&nbsp;难以诉说这种“糟糕”的感觉，即使是最亲近的人?<br>
                </div>
            </div>
            <div class="banner">
                <div class="text">
                    <img src="@/assets/images/ic_doctor.png" width="100%">
                    <p>当抑郁找上门，我们的生活仿佛被阴霾吞噬，想挣脱却很无力，好不容易却又被拽回去，反反复复。</p>
                </div>
            </div>
            <div class="section">
                <div class="section__heading">
                    <h3>命理知识</h3>
                </div>
                <table>
                    <tr>
                        <th>天干</th>
                        <th>五行</th>
                        <th>属性</th>
                        <th>物性</th>
                    </tr>
                    <tr>
                        <td>丙</td>
                        <td>火</td>
                        <td>礼</td>
                        <td>太阳</td>
                    </tr>
                    <tr>
                        <td>性格优点</td>
                        <td colspan="3">开朗、直爽、慷慨不计较、待人亲切、理解力、精力亦充沛做事积极、易得人好感。</td>
                    </tr>
                    <tr>
                        <td>性格缺点</td>
                        <td colspan="3">性急易冲动、性情飘忽不定、喜怒无常、有时自卑有时自大、较善变而三心二意。</td>
                    </tr>
                </table>
                <p class="text">
                    简介：<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;心理和命理是两个看似不同的领域，但它们之间确实存在着一定的联系。例如，心理学家可以借鉴命理学中的某些观点和方法来丰富自己的研究和实践；同时，命理学者也可以吸收心理学的科学精神和研究成果来提升自己的预测准确性和实用性。
                </p>
            </div>
            <div class="card">
                <div class="card__wrap">
                    <strong>心理咨询（不限咨询时长）</strong>
                    <div class="money">
                        <dfn>1折特惠</dfn>
                        <b>&yen;59.9</b>
                        <del>原价：599</del>
                    </div>
                    <div class="countdown">
                        <span class="icon"></span>
                        <span class="text">倒计时</span>
                        <span class="time">{{ times[0] }}</span>
                        <span class="text">分</span>
                        <span class="time">{{ times[1] }}</span>
                        <span class="text">秒</span>
                        <span class="time">{{ times[2] }}</span>
                    </div>
                    <div class="btn-pay" @click="handlePay">微信支付</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OneononeIndex',
    data() {
        return {
            times: [29, 59, 99],
            countdownIntervalId: null,
            code_url: null
        }
    },
    mounted() {
        this.startCountdown()
    },
    beforeDestroy() {
        this.stopCountdown()
    },
    methods: {
        startCountdown() {
            if (this.countdownIntervalId == null) {
                this.countdownIntervalId = setInterval(() => {
                    if (this.times[2] > 0) {
                        this.$set(this.times, 2, this.times[2] - 1)
                    } else if (this.times[1] > 0) {
                        this.$set(this.times, 2, 99)
                        this.$set(this.times, 1, this.times[1] - 1)
                    } else if (this.times[0] > 0) {
                        this.$set(this.times, 1, 59)
                        this.$set(this.times, 0, this.times[0] - 1)
                    } else {
                        this.$set(this.times, 0, 29)
                    }
                }, 10)
            }
        },
        stopCountdown() {
            if (this.countdownIntervalId != null) {
                clearInterval(this.countdownIntervalId)
                this.countdownIntervalId = null
            }
        },
        handlePay() {
            this.$api.post('/order/create_oneonone', {
                'logid_url': window.location.href
            }).then(res => {
                if (res.data.code === 0) {
                    window.location.href = res.data.data.code_url
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/oneonone/index.scss';
</style>
