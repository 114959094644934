<template>
    <div>
        <div class="page generating" v-if="step === 1">
            <div class="generating__header">
                <div class="generating__header-icon">
                    <img src="@/assets/images/generating.png">
                </div>
                <strong>您的专业报告正在生成中……</strong>
            </div>
            <div class="generating__content">
                <div class="tabs">
                    <div :class="{ tabs__item: true, 'tabs__item--active': generatingTabIndex == 0 }">结果汇总</div>
                    <div :class="{ tabs__item: true, 'tabs__item--active': generatingTabIndex == 1 }">报告分析</div>
                    <div :class="{ tabs__item: true, 'tabs__item--active': generatingTabIndex == 2 }">生成报告</div>
                </div>
                <div class="items">
                    <div class="items__item" v-for="(item, i) in generatingItems" :key="i">
                        {{ item.name }}
                        <div class="progress-bar">
                            <div class="progress-bar__out">
                                <span class="progress-bar__in" :style="{ width: item.progress + '%' }"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page page--pay" v-else-if="step === 2">
            <Header></Header>
            <div class="page__content">
                <div class="report">
                    <strong>您的报告摘要</strong>
                    <ul>
                        <li class="placeholder">答题数<span class="text">{{ order.questions_count }}题</span></li>
                        <li class="placeholder">完成时间<span class="text">{{ order.completed_at }}</span></li>
                        <li class="placeholder">用时<span class="text">{{ order.duration_seconds | secondsToDHMS }}</span></li>
                        <li class="placeholder">性别<span class="text">{{ order.gender | gender }}</span></li>
                        <li class="placeholder">年龄<span class="text">{{ order.age }}</span></li>
                        <li>专属测评报告及抑郁分值<span class="lock">待解锁</span></li>
                        <li>我的症状解析及改善建议<span class="lock">待解锁</span></li>
                        <li>抑郁情绪减压26发<span class="lock">待解锁</span></li>
                        <li>十死一生—战胜抑郁分享<span class="lock">待解锁</span></li>
                        <li>100本+心理治愈经典免费<span class="lock">待解锁</span></li>
                        <li>专家交流及心理知识分享<span class="lock">待解锁</span></li>
                    </ul>
                </div>
                <div class="pay_channels">
                    <div class="pay_channels__item pay_channels__item--Weixin" @click="handlePay('Weixin')">微信支付</div>
                    <!-- <div class="pay_channels__item pay_channels__item--Alipay" @click="handlePay('Alipay')">支付宝支付</div> -->
                </div>
                <p class="tips">
                    你的测评已完成！<br>
                    支付完成后查看测评结果<br>
                    已有 <dfn>876960</dfn> 人多与测试<br>
                    <dfn>98.6%</dfn> 的用户感觉很有用
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
    name: 'Pay',
    components: {
        Header
    },
    data() {
        return {
            step: 1,
            order: {},
            generatingTabIndex: -1,
            generatingItems: [],
            generatingIntervalId: null
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        startgenerating() {
            this.step = 1
            this.generatingIntervalId = setInterval(() => {
                if (this.generatingItems.length == 0) {
                    this.generatingTabIndex++
                    if (this.generatingTabIndex == 0) {
                        this.generatingItems = [
                            { name: '正在收集你的选项中', progress: 0 },
                            { name: '正在计算匹配结果中', progress: 0 },
                            { name: '正在生成汇总全部结果', progress: 0 }
                        ]
                    } else if (this.generatingTabIndex == 1) {
                        this.generatingItems = [
                            { name: '正在分析你的基础报告内容', progress: 0 },
                            { name: '正在分析整体内容匹配报告详情', progress: 0 }
                        ]
                    } else if (this.generatingTabIndex == 2) {
                        this.generatingItems = [
                            { name: '正在生成你的基础板块内容', progress: 0 },
                            { name: '正在生成你的详细完整报告', progress: 0 },
                            { name: '正在输出你的完整报告中', progress: 0 }
                        ]
                    }
                } else {
                    for (let i = 0; i < this.generatingItems.length; i++) {
                        if (this.generatingItems[i].progress < 100) {
                            let progress = this.generatingItems[i].progress + Math.floor(Math.random() * 20) + 1
                            if (progress > 100) {
                                progress = 100
                            }
                            this.generatingItems[i].progress = progress
                            break
                        }
                    }
                    if (this.generatingItems[this.generatingItems.length - 1].progress == 100) {
                        this.generatingItems = []
                        if (this.generatingTabIndex == 2) {
                            this.step = 2
                        }
                    }
                }
            }, 100)
        },
        fetchData() {
            this.$api.post('/order/info', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    this.order = res.data.data
                    this.startgenerating()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        handlePay(code) {
            this.$router.push({
                path: 'paying',
                query: {
                    order_no: this.order.order_no,
                    pay_channel_code: code
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pay.scss';
</style>
