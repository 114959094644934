<template>
    <div class="page page--success">
        <div class="page__header">
            <strong>一对一解答</strong>
            <div class="money"><dfn>&yen;</dfn>59.9</div>
        </div>
        <div class="page__content">
            <div class="payee">
                <div class="payee-item">
                    <label>收款方</label>
                    <b>乾森心理</b>
                </div>
            </div>
            <div class="commit">
                <button type="button" @click="onBridgeReady">立即支付</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OneononePay',
    data() {
        return {
            payParams: null,
            outTradeNo: null,
            checkPayIntervalId: null
        }
    },
    beforeCreate() {
        this.createWeixinJSBridgeReady()
    },
    beforeDestroy() {
        this.stopCheckPay()
    },
    methods: {
        createWeixinJSBridgeReady() {
            if (typeof window.WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                }
            }
        },
        onBridgeReady() {
            if (!this.payParams) {
                this.handlePay()
            }
            this.startCheckPay()
            window.WeixinJSBridge.invoke('getBrandWCPayRequest', this.payParams, function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
            });
        },
        handlePay() {
            this.$api.post('/pay/oneonone', { url: window.location.href }).then(res => {
                if (res.data.code === 0) {
                    this.outTradeNo = res.data.data.out_trade_no
                    this.payParams = res.data.data.pay_params
                    this.onBridgeReady()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        checkPay() {
            this.$api.post('/pay/paid', {
                out_trade_no: this.outTradeNo
            }).then(res => {
                if (res.data.code === 0) {
                    if (res.data.data.is_paid) {
                        this.paySuccess(res.data.data.order_no)
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        startCheckPay() {
            if (this.checkPayIntervalId == null) {
                this.checkPayIntervalId = setInterval(() => {
                    this.checkPay()
                }, 2000)
            }
        },
        stopCheckPay() {
            if (this.checkPayIntervalId != null) {
                clearInterval(this.checkPayIntervalId)
                this.checkPayIntervalId = null
            }
        },
        paySuccess(orderNo) {
            this.$router.replace({
                path: 'success',
                query: {
                    order_no: orderNo
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/oneonone/pay.scss';
</style>
