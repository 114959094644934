<template>
    <div class="header">
        <div class="header__back" @click="$router.go(-1)" v-if="back">
            <span class="arrow"></span>
        </div>
        <div class="header__title">抑郁（SDS）测试—专业版</div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: {
        back: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/header.scss';
</style>
