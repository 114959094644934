<template>
    <div class="page page--success" v-if="qrcodePath">
        <img :src="qrcodePath" width="100%">
        <small>请长按图片识别，添加老师一对一解答</small>
    </div>
</template>

<script>
export default {
    name: 'OneononeSuccess',
    data() {
        return {
            qrcodePath: null
        }
    },
    mounted() {
        this.fetchQrcode()
    },
    methods: {
        fetchQrcode() {
            this.$api.post('/weixin/fetch', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    if (res.data.data != null) {
                        this.qrcodePath = res.data.data.qrcode_path
                    } else {
                        alert('一对一老师信息获取失败，请稍后重试。')
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/oneonone/success.scss';
</style>
