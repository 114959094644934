export function gender(val) {
    if (val == 1) {
        return '男'
    } else if (val == 2) {
        return '女'
    }
    return ''
}

export function secondsToDHMS(totalSeconds) {
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    let remainingSeconds = totalSeconds % (24 * 60 * 60);
    const hours = Math.floor(remainingSeconds / (60 * 60));
    remainingSeconds %= (60 * 60);
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    let result = '';

    if (days > 0) {
        result += `${days}天`;
    }

    if (hours > 0 || (days > 0 && minutes > 0)) {
        result += `${hours}小时`;
    }

    if (minutes > 0 || (hours > 0 && seconds > 0)) {
        result += `${minutes}分钟`;
    }

    if (seconds > 0) {
        result += `${seconds}秒`;
    }

    return result;
}